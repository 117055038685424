export const TECHNOLOGIES = [
  { id: "Ruby on Rails", image: "ruby-on-rails.svg", name: "Ruby on Rails" },
  { id: "Google Search", image: "google-search.svg", name: "Google Search" },
  { id: "HTML/CSS", image: "html5.svg", name: "HTML/CSS" },
  { id: "JavaScript", image: "javascript.svg", name: "JavaScript" },
  { id: "jQuery", image: "jquery.svg", name: "jQuery" },
  { id: "Sass", image: "sass.svg", name: "Sass" },
  { id: "Postgres", image: "postgresql.svg", name: "Postgres" },
  { id: "SQL", image: "sql-logo.svg", name: "SQL" },
]

export const WORKS = [
  {
    title: "View City",
    strongText:
      "The view city section shows users a quick overview of the chosen location for living.",
    text:
      "They can short and see all the necessary information, including a short description, user reviews, a map, and information about regional taxes.",
    image: "view-city",
    ext: "png",
  },
  {
    title: "Machine Learning",
    strongText:
      "While working with the platform, we worked with many photos of cities.",
    text:
      "We used artificial intelligence to optimize the process of selecting images of cities and it saved almost 30 000 of working hours.",
    image: "machine-learning",
    ext: "png",
  },
  {
    title: "Working",
    strongText:
      "Age Line allows users to discover a set of possible workplace places.",
    text:
      "They can see the number of vacancies in certain cities, including reviews of companies there are considering.",
    image: "working",
    ext: "png",
    type: "higher",
  },
  {
    title: "Employers",
    strongText:
      "Users get a complete picture of an employer’s workplace environment and company values as to whether they are age-friendly and suitable for 50+ workers.",
    text: "",
    image: "employers",
    ext: "jpg",
  },
  {
    title: "Search",
    strongText:
      "Users can get all the information about any company simply by typing the name of the company.",
    text:
      "In addition, there can sort and filter results by popularity, state, ranking, and name. So results with full details (comments, reviews, descriptions, amount of vacancies) about a company will be displayed on the search results page.",
    image: "search",
    ext: "png",
  },
  {
    title: "Blog",
    strongText:
      "The Age Line Blog provides users with additional information about the different regions and cities concerning adding.",
    text: "",
    image: "blog",
    ext: "png",
  },
  {
    title: "Articles",
    strongText:
      "In articles, Age Line shares exciting insights that help seniors make the right decision when choosing a place to live.",
    text: "",
    image: "articles",
    ext: "png",
  },
]
