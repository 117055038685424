// extracted by mini-css-extract-plugin
export var ageLineBlock = "ageline-module--ageLineBlock--568ce";
export var billetShadow = "ageline-module--billet-shadow--926e4";
export var challenge = "ageline-module--challenge--e6b55";
export var colorScheme__background__grayPale = "ageline-module--colorScheme__background__gray-pale--52d5f";
export var feedback = "ageline-module--feedback--2189b";
export var heading = "ageline-module--heading--937ae";
export var heading_img = "ageline-module--heading_img--93603";
export var heading_text = "ageline-module--heading_text--f2e82";
export var higher = "ageline-module--higher--dcd4b";
export var howWorks = "ageline-module--howWorks--12b15";
export var icomoon = "ageline-module--icomoon--8a85a";
export var image = "ageline-module--image--5f724";
export var imageBlock = "ageline-module--imageBlock--9e4e3";
export var imageWrapper = "ageline-module--imageWrapper--143d4";
export var jobTitle = "ageline-module--jobTitle--3de2a";
export var lowercase = "ageline-module--lowercase--9a0dc";
export var name = "ageline-module--name--f8695";
export var numerous = "ageline-module--numerous--89a49";
export var realization = "ageline-module--realization--aba9e";
export var result = "ageline-module--result--1c9a2";
export var shine = "ageline-module--shine--7a0bb";
export var strong = "ageline-module--strong--65b85";
export var techStack = "ageline-module--techStack--240f0";
export var techStack__pic = "ageline-module--techStack__pic--ef4d8";
export var techStack__pic1 = "ageline-module--techStack__pic1--5d8db";
export var techStack__pic2 = "ageline-module--techStack__pic2--81a16";
export var textBlock = "ageline-module--textBlock--7982a";
export var transition = "ageline-module--transition--745d5";
export var transitionBackground = "ageline-module--transition-background--49f27";
export var transitionReverse = "ageline-module--transition-reverse--36f4f";
export var twoColumns = "ageline-module--twoColumns--50456";
export var uppercase = "ageline-module--uppercase--cc28f";
export var worksList = "ageline-module--worksList--3c678";